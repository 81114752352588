import db, { serverTimestamp, functions } from '@/firebase'
import color from 'color'
import JsBarcode from 'jsbarcode'

import { AsidDB, asidID, assetAttributeValue, IdentifierKeyedObject, IdentifierValue } from '@/types/typeAsid'
import { CategoryID } from '@/types/typeCategory'
import { TenantID } from '@/types/typeTenant'
import TenantManager from './tenantManager'
import { ElementID } from '@/modules/typeModules'
import BaseManager from './baseManager'

import { PlanDB } from '@/types/typePlan'
import databaseSchema from './databaseSchema'
import { DeepPartial, hasDBid, objectID } from '@/types/typeGeneral'
import { createID, getRegex, DEFAULT_CODE_VERSION } from '@/shared/sharedAsid'
import { getChunkedArray } from '@/helpers/arrayHelper'
import qrcodegen from '@/lib/qrcode-generator'
import { Timestamp } from '@/types/typeTimestamp'
import { INTERACTION_ASID_SLOT_MULTIPLIER } from '@/businessLogic/constants'
import { RPCRequestBackendCreateAsid, RPCResponseBackendCreateAsid } from '@/types/typeRPC'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import BackendConfigManager from './backendConfigManager'
import { typedWhere } from './dbHelper'

export default class AsidManager extends BaseManager {
  public static urlIdentifier = 'asid'
  public static codeVersion = DEFAULT_CODE_VERSION // a for testing

  public static defaultDocDB: AsidDB = databaseSchema.COLLECTIONS.ASID.__EMPTY_DOC__

  public static createLink(asid: objectID, baseUrl = '') {
    if (!baseUrl) baseUrl = `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_APP_BASE_URL}`
    if (!baseUrl.startsWith('http')) baseUrl = 'https://' + baseUrl.replace(/^https?:\/\//, '') // make sure its https://
    return `${baseUrl}/${asid}`
  }

  public static getRegex() {
    // https://regexr.com/4jk1n
    return getRegex()
  }

  public static extractAsidFromString(asid: string) {
    const regex = this.getRegex()
    const matches = asid.match(regex)
    return matches ? matches[0] : ''
  }

  public static createID(codeVersion = this.codeVersion) {
    return createID(codeVersion)
  }

  public static validateCodeTemplateSVGText(templateSVGText: string) {
    const placeholder = document.createElement('div')
    placeholder.innerHTML = templateSVGText

    const qrCodeEl = placeholder.querySelector('#echo-qr-code')

    if (!qrCodeEl) {
      throw 'svg template does not include id:echo-qr-code to place the WR code into.'
    }
  }

  /**
   * Retrieves the SVG text of a code template from a given URL or local file path.
   * @param svgTemplateIdentifier - The identifier of the SVG template to retrieve.
   * @returns The SVG text of the specified code template.
   * @throws An error if the specified template is not found.
   */
  public static async getCodeTemplateSVGTextFromUrl(svgTemplateIdentifier: string) {
    const svgUrl =
      svgTemplateIdentifier === '' || svgTemplateIdentifier.startsWith('_default_')
        ? 'ymyvCodeEchoCodeLogo'
        : svgTemplateIdentifier
    let svgText = ''

    if (!svgUrl.startsWith('http')) {
      // _default_{number}
      try {
        const template = await require('@/assets/echoCodeTemplates/' + svgUrl + '.svg?raw')
        svgText = template
      } catch (error) {
        throw `template ${svgUrl} not found`
      }
    } else {
      const response = await fetch(svgUrl)

      if (response.status !== 200) {
        throw `template ${svgUrl} not found`
      }

      svgText = await response.text()
    }

    return svgText
  }

  /**
   * create a svg from a template and replace the placeholders with the values
   * its async as images need to be fetched to be inlined
   */
  public static async getCodeSVG(
    asidID: string,
    baseUrl: string,
    templateSVGText: string,
    ecl: 'L' | 'M' | 'Q' | 'H',
    customText = ['', ''],
    customLogoUrl = '',
    bgColor = 'black',
    showPrint = false,
    variables = { testVar: 'testValue' } as { [key: string]: any }, // identifier 'i' and data Module 'd'
    backendConfig?: BackendConfigDB,
    logger = {
      warn: (msg: string) => console.warn(msg),
      error: (msg: string) => console.error(msg)
    }
  ) {
    bgColor = bgColor || 'black'
    const appUrl = this.createLink(asidID, baseUrl || undefined)

    const $templateSVGTextEl = document.createElement('div')
    $templateSVGTextEl.innerHTML = templateSVGText

    const kebabize = (str: string) =>
      str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())

    /**
     * use any of the ids in the svg as id or class to map to a value
     * the idea of using ids and classes instead of data attributes or {{}}-syntax is that the svg can be edited in a vector graphics program
     * the svg element iwith the id or class will be replaced with the content returned by the getContent function
     */
    const dataMapping = [
      {
        id: 'echo-attributes-valid',
        getContent: (svg: Element) => {
          if (!backendConfig) return 'false'
          const [isValid] = BackendConfigManager.validateDataDefinitionInput(
            variables['attribute'],
            Object.entries(backendConfig.asid.assetAttributeDefinitions).map(([key, value]) => ({
              __identifierKey__: key as keyof IdentifierKeyedObject,
              ...value
            })),
            true
          )

          return isValid ? 'true' : 'false'
        }
      },
      {
        id: 'echo-identifiers-valid',
        getContent: (svg: Element) => {
          if (!backendConfig) return 'false'
          const [isValid] = BackendConfigManager.validateDataDefinitionInput(
            variables['identifier'],
            Object.entries(backendConfig.asid.identifierDefinition).map(([key, value]) => ({
              __identifierKey__: key as keyof IdentifierKeyedObject,
              ...value
            })),
            true
          )
          return isValid ? 'true' : 'false'
        }
      },
      {
        id: 'echo-asid',
        getContent: (svg: Element) => asidID
      },
      {
        id: 'echo-asid-url',
        getContent: (svg: Element) => appUrl
      },
      {
        id: 'echo-asid-first',
        getContent: (svg: Element) => asidID.substr(0, 11)
      },
      {
        id: 'echo-asid-second',
        getContent: (svg: Element) => asidID.substr(12)
      },
      {
        id: 'echo-text',
        getContent: (svg: Element) => customText[0]
      },
      {
        id: 'echo-text-2',
        getContent: (svg: Element) => customText[1]
      },
      {
        id: 'echo-logo-url',
        getContent: (svg: Element) => customLogoUrl
      },
      {
        id: 'echo-bg-color',
        color: bgColor
      },
      {
        id: 'echo-bg-color-contrast',
        color: color(bgColor).isDark() ? '#fff' : '#000'
      },
      {
        id: 'echo-print-only',
        visibility: showPrint ? 'visible' : 'hidden'
      },
      {
        id: 'echo-qr-code',
        getContent: (svg: Element) => {
          const fgColor = svg.getAttribute('data-echo-qr-fg-color') || undefined
          const bgColor = svg.getAttribute('data-echo-qr-bg-color') || undefined

          const qrCode = AsidManager.getQrCodeSvg(asidID, baseUrl, ecl, fgColor, bgColor)
          return qrCode
        }
      },
      // variable substitution
      // echo-data-d2 echo-identifier-i1
      ...Object.entries(variables).flatMap(([varGroupName, vars]) =>
        (vars === undefined ? [] : Object.entries(vars)).map(([varName, value]) => ({
          id: `echo-${kebabize(varGroupName)}-${kebabize(varName)}`,
          getContent: (svg: Element) => {
            // check display modifiers
            return `${value || ''}` // trick to not display 'null'
          }
        }))
      )
    ] as const

    for (const mapping of dataMapping) {
      const svgElements = $templateSVGTextEl.querySelectorAll(`#${mapping.id}, .${mapping.id}`)
      for (const $templateSvgEl of svgElements) {
        let content: string | null = 'getContent' in mapping ? mapping.getContent?.($templateSvgEl) : ''

        // check display modifiers
        // those modifiers can be used to display not a text but a barcode or a qr code or image
        if ($templateSvgEl.classList.contains('echo-opt-barcode')) {
          const $barcodeSvgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

          if (!content) {
            logger.warn(`no content given for echo-opt-barcode, ${mapping.id}`)
            continue
          }

          console.log('-------------content', content)
          // https://lindell.me/JsBarcode/generator/
          JsBarcode($barcodeSvgEl, content, {
            format: $templateSvgEl.getAttribute('data-echo-barcode-type') || 'code128',
            width: +($templateSvgEl.getAttribute('data-echo-barcode-width') || 10),
            height: +($templateSvgEl.getAttribute('data-echo-barcode-height') || 10),
            displayValue: !!$templateSvgEl.getAttribute('data-echo-barcode-display-value') || false,
            margin: +($templateSvgEl.getAttribute('data-echo-barcode-margin') || 0)
          })
          // JsBarcode(svg).CODE128(content, { text: content })
          console.log($barcodeSvgEl)
          $templateSvgEl.setAttribute('viewBox', $barcodeSvgEl.getAttribute('viewBox') || '')
          $templateSvgEl.setAttribute('preserveAspectRatio', 'none') // to actually scale the barcode to the container size
          while ($templateSvgEl.firstChild) {
            $templateSvgEl.removeChild($templateSvgEl.firstChild)
          }
          // el.appendChild(svg)
          content = $barcodeSvgEl.outerHTML
        } else if ($templateSvgEl.classList.contains('echo-opt-qrcode')) {
          const fgColor = $templateSvgEl.getAttribute('data-echo-qr-fg-color') || '#000'
          const bgColor = $templateSvgEl.getAttribute('data-echo-qr-bg-color') || '#fff'
          const templateEcl = ($templateSvgEl.getAttribute('data-echo-qr-ecl') as 'L' | 'M' | 'Q' | 'H') || ecl

          const qr = qrcodegen.QrCode.encodeText(content, this.eclStringToEcl(templateEcl))
          const qrCode = this.qrToSvgString(qr, 0, bgColor, fgColor)

          content = qrCode
        } else if ($templateSvgEl.classList.contains('echo-opt-image')) {
          try {
            // get the image url from the content and convert it to base64
            const imageUrl = content

            // if no image url is given, skip this step
            if (!imageUrl) {
              logger.warn(`no image url given for echo-opt-image, ${mapping.id}`)
              continue
            }

            const imageBase64 = await this.imageUrlToBase64(imageUrl)

            // create a new image element
            const $imageEl = document.createElementNS('http://www.w3.org/2000/svg', 'image')
            $imageEl.setAttribute('href', imageBase64)
            $imageEl.setAttribute('width', '100%')
            $imageEl.setAttribute('height', '100%')

            // replace the content with the image
            while ($templateSvgEl.firstChild) {
              $templateSvgEl.removeChild($templateSvgEl.firstChild)
            }

            content = $imageEl.outerHTML
          } catch (error) {
            console.error('error placing image', error)
          }
        } else if ($templateSvgEl.classList.contains('echo-opt-visible')) {
          // if the element has the class echo-opt-visible, the content will only be displayed if the content is not empty
          if (!content || content === 'false') {
            $templateSvgEl.setAttribute('visibility', 'hidden')
            console.debug('echo-opt-visible', mapping.id, 'hidden')
            continue
          }

          // set content to null as it is only used to set the visibility
          content = null
        } else if ($templateSvgEl.classList.contains('echo-opt-hidden')) {
          // if the element has the class echo-opt-hidden, the content will only be displayed if the content is empty
          if ((content && content !== null && content !== 'false') || content === 'true') {
            console.debug('echo-opt-hidden', mapping.id, 'hidden')
            $templateSvgEl.setAttribute('visibility', 'hidden')
            continue
          }

          // set content to null as it is only used to set the visibility
          content = null
        }

        if ('getContent' in mapping && content !== null) $templateSvgEl.innerHTML = content

        if ('color' in mapping) $templateSvgEl.setAttribute('fill', mapping.color)
        if ('visibility' in mapping) $templateSvgEl.setAttribute('visibility', mapping.visibility)
      }
    }

    return $templateSVGTextEl
  }

  private static async imageUrlToBase64(imageUrl: string) {
    const convertBlobToBase64 = (blobData: Blob) => {
      return new Promise<string>((resolve, reject) => {
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          if (e.target) resolve(e.target.result as string)
          else reject('no target')
          //this.isInput = true
        }

        reader.readAsDataURL(blobData)
      })
    }

    // fetch the image and convert it to base64
    const fetchResponse = await fetch(imageUrl)

    // convert response to blob
    const blobData = await fetchResponse.blob()

    // Start the reader job - read file as a data url (base64 format)
    return convertBlobToBase64(blobData)
  }

  /**
   * https://github.com/nayuki/QR-Code-generator/blob/master/typescript-javascript/qrcodegen-input-demo.ts?ts=4
   *  Returns a string of SVG code for an image depicting the given QR Code, with the given number
   * of border modules. The string always uses Unix newlines (\n), regardless of the platform.
   * @param qr
   * @param border
   * @param lightColor
   * @param darkColor
   * @returns
   */
  private static qrToSvgString(qr: qrcodegen.QrCode, border: number, lightColor: string, darkColor: string): string {
    if (border < 0) throw new RangeError('Border must be non-negative')
    const parts: Array<string> = []
    for (let y = 0; y < qr.size; y++) {
      for (let x = 0; x < qr.size; x++) {
        if (qr.getModule(x, y)) parts.push(`M${x + border},${y + border}h1v1h-1z`)
      }
    }
    return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 ${qr.size + border * 2} ${
      qr.size + border * 2
    }" stroke="none">
	<rect width="100%" height="100%" fill="${lightColor}"/>
	<path d="${parts.join(' ')}" fill="${darkColor}"/>
</svg>
`
  }

  private static eclStringToEcl(ecl: 'L' | 'M' | 'Q' | 'H') {
    if (!['L', 'M', 'Q', 'H'].includes(ecl))
      throw `Unknown ECL given "${ecl}", supported values are "'L' | 'M' | 'Q' | 'H'"`

    return ecl === 'L'
      ? qrcodegen.QrCode.Ecc.LOW
      : ecl === 'M'
      ? qrcodegen.QrCode.Ecc.MEDIUM
      : ecl === 'H'
      ? qrcodegen.QrCode.Ecc.HIGH
      : qrcodegen.QrCode.Ecc.QUARTILE
  }

  public static getQrCodeSvg(
    asidID: string,
    baseUrl: string,
    ecl: 'L' | 'M' | 'Q' | 'H',
    fgColor = '#000',
    bgColor = '#fff'
  ) {
    const qr = qrcodegen.QrCode.encodeText(this.createLink(asidID, baseUrl || undefined), this.eclStringToEcl(ecl))
    return this.qrToSvgString(qr, 0, bgColor, fgColor)
  }

  public static getDebugQrCodeSvg(url: string, ecl: 'L' | 'M' | 'Q' | 'H', fgColor = '#000', bgColor = '#fff') {
    const text = url
    const qr = qrcodegen.QrCode.encodeText(text, this.eclStringToEcl(ecl))

    // Returns a string to describe the given list of segments.
    function describeSegments(segs: Array<qrcodegen.QrSegment>): string {
      if (segs.length == 0) return 'none'
      else if (segs.length == 1) {
        const mode: qrcodegen.QrSegment.Mode = segs[0].mode
        const Mode = qrcodegen.QrSegment.Mode
        if (mode == Mode.NUMERIC) return 'numeric'
        if (mode == Mode.ALPHANUMERIC) return 'alphanumeric'
        if (mode == Mode.BYTE) return 'byte'
        if (mode == Mode.KANJI) return 'kanji'
        return 'unknown'
      } else return 'multiple'
    }

    return {
      mask: qr.mask,
      version: qr.version,
      ecl: 'LMQH'.charAt(qr.errorCorrectionLevel.ordinal),
      mode: describeSegments(qrcodegen.QrSegment.makeSegments(text)),
      QRsvg: this.qrToSvgString(qr, 0, bgColor, fgColor)
    }
  }

  public static getQrCodeStatistics(asidID: string, baseUrl: string, ecl: 'L' | 'M' | 'Q' | 'H') {
    const text = this.createLink(asidID, baseUrl || undefined)
    const qr = qrcodegen.QrCode.encodeText(text, this.eclStringToEcl(ecl))

    // Returns a string to describe the given list of segments.
    function describeSegments(segs: Array<qrcodegen.QrSegment>): string {
      if (segs.length == 0) return 'none'
      else if (segs.length == 1) {
        const mode: qrcodegen.QrSegment.Mode = segs[0].mode
        const Mode = qrcodegen.QrSegment.Mode
        if (mode == Mode.NUMERIC) return 'numeric'
        if (mode == Mode.ALPHANUMERIC) return 'alphanumeric'
        if (mode == Mode.BYTE) return 'byte'
        if (mode == Mode.KANJI) return 'kanji'
        return 'unknown'
      } else return 'multiple'
    }

    return {
      mask: qr.mask,
      version: qr.version,
      ecl: 'LMQH'.charAt(qr.errorCorrectionLevel.ordinal),
      mode: describeSegments(qrcodegen.QrSegment.makeSegments(text))
    }
  }

  public static async add(authEmail: string, asidID: string, fields: DeepPartial<AsidDB> = {}) {
    const tenantID: keyof AsidDB = 'tenantID'
    if (tenantID in fields) fields.dateAssigned = serverTimestamp() as any

    return this.addDoc(this.getDbDocReference(asidID), authEmail, fields, this.defaultDocDB)
  }

  public static activateASID(asidID: objectID, authEmail: string, fields: DeepPartial<AsidDB> = {}) {
    const updInstr = this.prepareUpdateHelper<AsidDB>(authEmail, fields)

    // dont pass timestamp to prepareUpdateHelper as it would flattedn the timepstamp instructions
    const tmpAsidDoc: Partial<AsidDB> = {
      ...updInstr,
      activated: true,
      dateActivated: serverTimestamp()
    }

    return this.getDbDocReference(asidID).update(tmpAsidDoc)
  }

  public static async activateAnyASID(
    authEmail: string,
    tenantID: TenantID,
    fields: DeepPartial<AsidDB> = {}
  ): Promise<asidID> {
    const updInstr = this.prepareUpdateHelper<AsidDB>(authEmail, fields)

    // dont pass timestamp to prepareUpdateHelper as it would flattedn the timepstamp instructions
    const tmpAsidDoc: Partial<AsidDB> = {
      ...updInstr,
      activated: true,
      dateActivated: serverTimestamp()
    }

    // if asidID is 'any' activate any not activated asid in a transaction
    // find any non activated asid
    let asidQuery = typedWhere<AsidDB>(this.getDbCollectionReference(), { activated: false }, '==', false)
    asidQuery = typedWhere<AsidDB>(asidQuery, { tenantID: '' }, '==', tenantID)
    asidQuery = asidQuery.limit(1)

    const asidDocSnap = await asidQuery.get()

    if (asidDocSnap.empty) throw 'no not activated ECHO CODE found [20240306]'

    const notActivatedAsidIDRef = asidDocSnap.docs[0].ref

    await db.runTransaction(async (transaction) => {
      const asidSnapshot = await transaction.get(notActivatedAsidIDRef)

      const asidDoc = asidSnapshot.data() as AsidDB

      if (asidDoc.activated) throw 'ECHO CODE already activated. Try to activate again. [20240307]'

      transaction.update(notActivatedAsidIDRef, tmpAsidDoc)
    })

    return notActivatedAsidIDRef.id
  }

  public static async assignAndActivateASID(
    asidID: objectID,
    authEmail: string,
    tenantID: TenantID,
    fields: DeepPartial<AsidDB> = {}
  ) {
    const updInstr = this.prepareUpdateHelper<AsidDB>(authEmail, fields)

    const tmpAsidDoc: Partial<AsidDB> = {
      ...updInstr,
      activated: true,
      dateActivated: serverTimestamp(),
      dateAssigned: serverTimestamp(),
      tenantID
    }

    return this.getDbDocReference(asidID).update(tmpAsidDoc)
  }

  public static async backendCreateAsid(tenantID: TenantID) {
    const RPCRequestBackendCreateAsid: RPCRequestBackendCreateAsid = { tenantID }
    const callableBackendCreateAsidRPC = functions.httpsCallable('backendCreateAsidRPC')
    const rpcResponse = await callableBackendCreateAsidRPC(RPCRequestBackendCreateAsid)

    return (rpcResponse.data as RPCResponseBackendCreateAsid).asidID
  }

  public static updateAsid(
    asidID: objectID,
    authEmail: string,
    identifierValue: IdentifierValue,
    assetAttributeValue: assetAttributeValue,
    categoryIDs: CategoryID[]
  ) {
    const tmpAsidDocDoc: DeepPartial<AsidDB> = {
      identifierValue,
      assetAttributeValue,
      categoryIDs
    }

    return this.updateDoc(this.getDbDocReference(asidID), authEmail, tmpAsidDocDoc)
  }

  public static update(asidId: asidID, authEmail: string, fields: DeepPartial<AsidDB>) {
    return this.updateDoc(this.getDbDocReference(asidId), authEmail, fields)
  }

  public static activateBatch(asidId: asidID, authEmail: string, batch: firebase.default.firestore.WriteBatch) {
    const tmpAsidDoc: DeepPartial<AsidDB> = {
      activated: true,
      dateActivated: serverTimestamp() as Timestamp
    }

    return this.updateDocBatch(this.getDbDocReference(asidId), authEmail, tmpAsidDoc, batch)
  }

  public static updateBatch(
    asidId: asidID,
    authEmail: string,
    fields: DeepPartial<AsidDB>,
    batch: firebase.default.firestore.WriteBatch
  ) {
    return this.updateDocBatch(this.getDbDocReference(asidId), authEmail, fields, batch)
  }

  public static assignAsids(tenantId: TenantID, authEmail: string, asids: objectID[]) {
    const batch = db.batch()
    for (const asidChunk of getChunkedArray(asids, 400)) {
      for (const asidID of asidChunk) {
        const asidUpdate: Partial<AsidDB> = {
          tenantID: tenantId,
          dateAssigned: serverTimestamp()
        }
        this.updateBatch(asidID, authEmail, asidUpdate, batch)
      }
    }

    return batch.commit()
  }

  public static getAvailableInteractionsCount(plan: PlanDB) {
    return plan.availableAsidSlots * INTERACTION_ASID_SLOT_MULTIPLIER + plan.additionalInteractions
  }

  public static onSnapshot(asidID: string, onNext: (data: AsidDB & hasDBid) => void, onError: (e: any) => void) {
    return this.onSnapshotHelper<AsidDB>(this.getDbDocReference(asidID), onNext, (d) => d, onError)
  }

  public static get(asidID: string) {
    return this.getDocHelper<AsidDB>(this.getDbDocReference(asidID))
  }

  public static getWhere<T>(path: DeepPartial<T>, op: firebase.default.firestore.WhereFilterOp, value: any) {
    return this.getWhereHelper<AsidDB>(this.getDbCollectionReference(), path, op, value)
  }

  public static onPlanData(tenantId: TenantID, cb: (data: PlanDB) => void, error: (error: Error) => void) {
    return TenantManager.getDbPlanDocReference(tenantId).onSnapshot((d) => cb(d.data() as PlanDB), error)
  }

  public static getDbCollectionReference() {
    return db.collection(databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__())
  }

  public static getDbDocReference(asid: ElementID) {
    return this.getDbCollectionReference().doc(asid)
  }
}
