
import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt, faEdit, faExternalLinkAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'


import { AsidDB, IdentifierKeyedObject, IdentifierValue } from '@/types/typeAsid'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import { DataDefinition, DataDefinitionObject, isDataDefinitionKey, DataValueObject } from '@/types/typeDataDefinition'
import VImageUploadModal from './image/VImageUploadModal.vue'
import AsidManager from '@/database/asidManager'
import { typedWhere, typedWhereSartsWith } from '@/database/dbHelper'
import { arrayUnique } from '@/helpers/arrayHelper'
import { dbPrivileges } from '@/helpers/privileges'
import CategoryHelper from '@/database/categoryHelper'
import BackendConfigManager from '@/database/backendConfigManager'


library.add(faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt, faEdit, faExternalLinkAlt, faCheck, faTimes)


@Component({
  components: {
    VTooltipIconHelp,
    StreamBarcodeReader,
    VImageUploadModal
  }
})
export default class VFormDataEntry extends Vue {

  @PropSync('dataValueObject', { type: Object }) readonly dataValueObjectSync!: DataValueObject

  @Prop({ type: Object, required: true }) readonly dataDefinitionObject!: DataDefinitionObject

  @Prop({ type: Boolean, required: false, default: false }) readonly displayBarcodeScanner!: boolean

  // uploadPath prop
  @Prop({ type: String, required: false, default: () => '' }) readonly uploadPath!: string

  @Prop({ type: String, required: false, default: () => '' }) readonly documentPath!: string

  // disable image upload prop
  @Prop({ type: Boolean, required: false, default: false }) readonly disableImageUpload!: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean

  // autocomplete identifeirs prop
  @Prop({ type: Boolean, required: false, default: () => false }) readonly autocomplete!: boolean

  @Prop({ type: Array, default: () => [] })
  readonly hideTypes!: DataDefinition['datatype'][]

  // categories to limit the display of entries
  @Prop({ type: Array, default: () => ['ALL_CATEGORIES'] }) readonly categories!: string[]

  //
  public showDataEntryBasedOnCategories(dataDefinition: DataDefinition) {
    if (dataDefinition.categories.length === 0) return true // if no categories are set, show the data entry
    if (this.categories.includes('ALL_CATEGORIES')) return true // if the data entry is in the ALL_CATEGORIES category, show it

    return CategoryHelper.isElementActiveForAsidRef(dataDefinition.categories, this.categories, this.$categories)
  }

  public imageUploadModalActiveForDataKey: isDataDefinitionKey = ''

  public get isImageUploadModalActiveForDataKey() {
    return this.imageUploadModalActiveForDataKey !== ''
  }

  public set isImageUploadModalActiveForDataKey(value: boolean) {
    if (value === false)
      this.imageUploadModalActiveForDataKey = ''
  }

  // key of the dataValueObjectSync beeing edited
  public editingAutogenerated: string = ''


  public dataDefinitions: (DataDefinition & { __identifierKey__: isDataDefinitionKey })[] = []

  // #region autocomplete

  public identifierAutocompleteData: { [key: string]: (string | number | null | object | boolean)[] } = {}

  public isFetchingIdentifierAutocompleteData = false

  get hasAsidReadPrivilege() {
    return this.$auth.userHasAllPrivilege([dbPrivileges.ASID_READ])
  }

  public async onGetIdentifierAutocomplData(identifierKey: keyof IdentifierValue, text: string) {

    if (this.$backendConfig.asid.identifierDefinition[identifierKey].validatorType === 'validatorType_choices') {
      // if choices are the validator, just show the choices in the dropdown
      this.identifierAutocompleteData[identifierKey] = this.$backendConfig.asid.identifierDefinition[identifierKey].validator.choices || []
    } else if (this.hasAsidReadPrivilege) {

      this.isFetchingIdentifierAutocompleteData = true

      const query = typedWhere<AsidDB>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
      const queryOC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text)
      const queryUC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toUpperCase())
      const queryLC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toLowerCase())

      const queries = [queryOC, queryUC, queryLC]
      const queryRequests = queries.map(q => q.limit(50).get())

      const queryResults = (await Promise.all(queryRequests))

      const responseDocuments = queryResults.flatMap(r => r.docs.map((d) => d.data() as AsidDB).map(asid => asid.identifierValue[identifierKey]))
      this.identifierAutocompleteData[identifierKey] = arrayUnique(responseDocuments)
      this.isFetchingIdentifierAutocompleteData = false
    } else {
      this.identifierAutocompleteData[identifierKey] = [text]
    }

  }
  // #endregion autocomplete


  public validationErrorMessages: { [key: string]: string } = {}

  public validateInput() {
    const [valid, validationErrorMessages] = BackendConfigManager.validateDataDefinitionInput(this.dataValueObjectSync, this.dataDefinitions)

    this.validationErrorMessages = {}

    Object.entries(validationErrorMessages).forEach(([key, value]) => {
      this.$set(this.validationErrorMessages, key, value)
    })

    return valid
  }

  //#region barcodeModal
  public isBarcodeScannerModalActive = false
  private barcodeIdentifierIndex = 0
  public onOpenBarcodeModal(index: number) {
    this.barcodeIdentifierIndex = index
    this.isBarcodeScannerModalActive = true
  }
  public onBarcodeDecode(value: string, b: any, c: any) {
    this.isBarcodeScannerModalActive = false
    console.log(value, b, c)
    this.dataValueObjectSync[this.dataDefinitions[this.barcodeIdentifierIndex].__identifierKey__] = value
  }
  public onBarcodeLoaded() {
    // this.isBarcodeScannerModalActive = false
  }

  /** on dataValueObjectSync convert an empty tring to null */
  @Watch('dataValueObjectSync', { deep: true, immediate: true })
  private onDataValueObjectSyncChanged() {
    Object.entries(this.dataValueObjectSync)
      .forEach(([key, value]) => {
        if (value === '') {
          this.$set(this.dataValueObjectSync, key, null)
        }
        // if the type is boolean, convert null to false
        // => dont do that as this causes unsaved changes everytime even if the user does not change anything
        // if (this.dataDefinitions.find(d => d.__identifierKey__ === key)?.datatype === 'boolean' && value === null) {
        //   this.$set(this.dataValueObjectSync, key, false)
        // }
      })
  }


  get isReady() {
    console.log(this.dataDefinitions.length, this.dataValueObjectSync)
    return this.dataDefinitions.length <= Object.keys(this.dataValueObjectSync).length
  }

  @Watch('dataDefinitionObject', { deep: true, immediate: true })
  public init() {
    this.dataDefinitions = Object.entries(this.dataDefinitionObject).map(([key, value]) => ({
      __identifierKey__: key as keyof IdentifierKeyedObject,
      ...value
    }))
      .filter(e => e.name || e.title)
      // filter out all dataDefinitions that are in the hideTypes array
      .filter(e => !this.hideTypes.includes(e.datatype))
      // sort first by order and if order is the same, sort by __identifierKey__
      .sort((a, b) => a.order - b.order || a.__identifierKey__.localeCompare(b.__identifierKey__, 'en', { numeric: true }))

  }

  created() {
    // this.init()
  }

  //#endregion barcodeModal

}
