import db from '@/firebase'

import { TenantID } from '@/types/typeTenant'
import BaseManager from './baseManager'

import { BackendConfigDB } from '@/types/typeBackendConfig'
import databaseSchema from './databaseSchema'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { DataDefinition, DataValueObject, isDataDefinitionKey } from '@/types/typeDataDefinition'

export default class BackendConfigManager extends BaseManager {
  public static defaultDocDB: BackendConfigDB = databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__EMPTY_DOC__ // todo move to respective managers
  public static requiredPrivileges = databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__

  public static validateIdentifierName(identifierName: string) {
    return !!identifierName.match(/^[a-zA-Z0-9_&+-]+$/g)
  }

  /**
   *
   * @param dataValueObjectSync
   * @param dataDefinitions
   * @returns [boolean, { [key: string]: string }] // [isValid, validationErrorMessages]
   */
  public static validateDataDefinitionInput(
    dataValueObjectSync: DataValueObject,
    dataDefinitions: (DataDefinition & { __identifierKey__: isDataDefinitionKey })[],
    ignoreAllowEmpty = false // overrides datadefinitions allowEmpty
  ): [boolean, { [key: string]: string }] {
    const validationErrorMessages: { [key: string]: string } = {}

    // check for all dataValueObjectSync keys if they are valid according to the dataDefinitionObject
    // if not, add a warning to the dataValueObjectSync

    Object.entries(dataValueObjectSync).forEach(([key, value]) => {
      const dataDefinition = dataDefinitions.find((d) => d.__identifierKey__ === key)
      if (!dataDefinition) return

      if (!ignoreAllowEmpty) {
        if (value === null && dataDefinition.allowEmpty === true) return

        // check allow empty
        if (
          dataDefinition.allowEmpty === false &&
          (value === '' || value === null) &&
          dataDefinition.datatype !== 'auto_generated'
        ) {
          validationErrorMessages[key] = `value for "${dataDefinition.title}" must not be empty`
        }
      }

      switch (dataDefinition.validatorType) {
        case 'validatorType_none':
          break
        case 'validatorType_regex': {
          const regex = new RegExp(dataDefinition.validator.regex)
          if (!regex.test(value) || value === null) {
            validationErrorMessages[
              key
            ] = `value for "${dataDefinition.title}" does not match the regex ${dataDefinition.validator.regex}`
          }
          break
        }
        case 'validatorType_minMax': {
          // cast everyting to number for comparison
          if (
            Number(value) < Number(dataDefinition.validator.minMax[0]) ||
            Number(value) > Number(dataDefinition.validator.minMax[1])
          ) {
            validationErrorMessages[
              key
            ] = `value for "${dataDefinition.title}" must be between ${dataDefinition.validator.minMax[0]} and ${dataDefinition.validator.minMax[1]}`
          }
          break
        }
        case 'validatorType_value': {
          if (
            (dataDefinition.datatype === 'string' && dataDefinition.validator.value !== value) ||
            (dataDefinition.datatype === 'number' && dataDefinition.validator.value !== +value) ||
            (dataDefinition.datatype === 'boolean' && dataDefinition.validator.value !== value)
          ) {
            validationErrorMessages[
              key
            ] = `value for "${dataDefinition.title}" must equal ${dataDefinition.validator.value}`
          }
          break
        }
        case 'validatorType_choices': {
          if (
            !(dataDefinition.validator.choices as string[]).includes(value) ||
            (dataDefinition.allowEmpty === true && value === '')
          ) {
            validationErrorMessages[key] = `value for ${
              dataDefinition.title
            } must be one of ${dataDefinition.validator.choices.join(', ')}`
          }
          break
        }
        case 'validatorType_pattern':
          break
        default: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const type: never = dataDefinition.validatorType
          break
        }
      }

      if (dataDefinition.datatype === 'email') {
        const regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')
        if (value !== '' && value !== null && !regex.test(value)) {
          validationErrorMessages[key] = `value for "${dataDefinition.title}" is not a valid email address`
        }
      }
    })

    return [Object.keys(validationErrorMessages).length === 0, validationErrorMessages]
  }

  // todo this will be done using a cloud function in the future
  public static async add(tenantId: TenantID, authEmail: string, fields: DeepPartial<BackendConfigDB> = {}) {
    return await this.addDoc(this.getDbDocReference(tenantId), authEmail, fields, this.defaultDocDB)
  }

  public static async update(tenantId: TenantID, authEmail: string, fields: DeepPartial<BackendConfigDB> = {}) {
    return this.updateDoc(this.getDbDocReference(tenantId), authEmail, fields)
  }

  public static async get(tenantId: TenantID) {
    return this.getDocHelper<BackendConfigDB>(this.getDbDocReference(tenantId))
  }

  public static getDbDocReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__DOCUMENT_PATH__(tenantId))
  }

  public static onSnapshot(
    tenantId: string,
    onNext: (data: BackendConfigDB & hasDBid) => void,
    onError: (e: any) => void
  ) {
    return this.onSnapshotHelper<BackendConfigDB>(this.getDbDocReference(tenantId), onNext, (d) => d, onError)
  }
}
